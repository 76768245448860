import React from "react";
import { AuthGuard, PublicRoute } from "components";
import Icon from "@mui/material/Icon";

import FormHookEdit from "pages/Private/Users/Edit";
// lazy loading of components for better performance
const Login = React.lazy(() => import("pages/Public/Login"));
const ResetPassword = React.lazy(() => import("pages/Public/ResetPassword"));
const SubmitPassword = React.lazy(() => import("pages/Public/SubmitPassword"));
const Dashboard = React.lazy(() => import("pages/Private/Dashboard"));
const Users = React.lazy(() => import("pages/Private/Users"));
const CreateUser = React.lazy(() => import("pages/Private/Users/Create"));
const Roles = React.lazy(() => import("pages/Private/Roles"));
const CreateRole = React.lazy(() => import("pages/Private/Roles/Create"));
const EditRole = React.lazy(() => import("pages/Private/Roles/Edit"));
const Permissions = React.lazy(() => import("pages/Private/Permissions"));
const CreatePermission = React.lazy(() => import("pages/Private/Permissions/Create"));
const EditPermission = React.lazy(() => import("pages/Private/Permissions/Edit"));
const CookiePolicy = React.lazy(() => import("pages/Public/CookiePolicy"));
// routes for the sidebar
const routes = ({ t }) => [
  {
    type: "title",
    key: "iam",
    route: "/dashboard",
    component: <Dashboard title="menu.dashboard" />,
  },
  {
    key: "index",
    route: "*",
    component: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    key: "reset-password",
    route: "/reset-password",
    component: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    key: "submit-password",
    route: "/submit-password",
    component: (
      <PublicRoute>
        <SubmitPassword />
      </PublicRoute>
    ),
  },
  {
    key: "cookie-policy",
    route: "/cookie-policy",
    component: (
      <PublicRoute>
        <CookiePolicy />
      </PublicRoute>
    ),
  },
  {
    type: "collapse",
    name: t("menu.users"),
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    menuPermission: "mnuUsers",
    component: (
      <AuthGuard>
        <Users />
      </AuthGuard>
    ),
  },
  {
    key: "create-user",
    name: t("menu.createUser"),
    route: "/users/create",
    component: (
      <AuthGuard>
        <CreateUser title="menu.createUser" />
      </AuthGuard>
    ),
  },
  {
    key: "user-edit",
    name: t("menu.usersEdit"),
    route: "/user/edit/:id",
    component: (
      <AuthGuard>
        <FormHookEdit title="menu.usersEdit" />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: t("menu.roles"),
    key: "roles",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/roles",
    menuPermission: "mnuRoles",
    component: (
      <AuthGuard>
        <Roles title="menu.roles" />
      </AuthGuard>
    ),
  },
  {
    key: "create-role",
    route: "/roles/create",
    component: (
      <AuthGuard>
        <CreateRole title="menu.createrole" />
      </AuthGuard>
    ),
  },
  {
    name: t("menu.editrole"),
    key: "edit-role",
    route: "/roles/edit/:id",
    component: (
      <AuthGuard>
        <EditRole title="menu.editrole" />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: t("menu.permissions"),
    key: "permissions",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/permissions",
    menuPermission: "mnuPermissions",
    component: (
      <AuthGuard>
        <Permissions title="menu.permissions" />
      </AuthGuard>
    ),
  },
  {
    key: "create-permission",
    route: "/permissions/create",
    component: (
      <AuthGuard>
        <CreatePermission title="menu.createpermission" />
      </AuthGuard>
    ),
  },
  {
    name: t("menu.editpermission"),
    key: "edit-permission",
    route: "/permissions/edit/:id",
    component: (
      <AuthGuard>
        <EditPermission title="menu.editpermission" />
      </AuthGuard>
    ),
  },
];

export default routes;
