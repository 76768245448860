import { MDBox, MDTypography } from "components";
import industryDataModels from "assets/images/industry-data-models.svg";
import solvership from "assets/images/solvership.svg";
import "../../assets/css/fonts.css";

function PoweredBy({ showIDMLogo }) {
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" textAlign="center" pt={4}>
      {showIDMLogo ? (
        <img
          src={industryDataModels}
          alt="Industry data models"
          style={{ width: "20%", marginRight: "1rem" }}
        />
      ) : null}
      <MDTypography color="solvershipAlmostBlack" variant="caption" fontFamily="The Future">
        Powered by
      </MDTypography>
      <img src={solvership} alt="Solvership" style={{ width: "25%", marginLeft: "1rem" }} />
    </MDBox>
  );
}

export default PoweredBy;
