import axios from "axios";
import { baseURL } from "config";

export async function InitAuth() {
  const path = "destination/initauth";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
    redirectURI: window.location.href,
  });

  try {
    const initAuthResponse = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    return initAuthResponse;
  } catch (error) {
    return error;
  }
}

export async function Auth() {
  const path = "destination/auth";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    const authResponse = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    if (authResponse.status === 200) {
      return authResponse;
    }
  } catch (error) {
    return error;
  }
}

export async function Ask2Fa(body) {
  const path = "login/ask2fa";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export async function Submit2Fa(body) {
  const path = "login/submit2fa";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export async function Logout() {
  const path = "destination/logout";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  try {
    const response = await axios.post(url, "", {
      headers: getHeaders(),
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function Ask2Reset(body) {
  const path = "reset/askreset";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
  });

  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export async function ResetPassword(body, session) {
  const path = "reset/confirmreset";
  const url = `${baseURL}/${path}`;

  const getHeaders = () => ({
    "Content-Type": "application/json",
    reset_session: session,
  });

  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}
