import { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import MDButtonRoot from "./MDButtonRoot";

const MDLoadingButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, disabled, isLoading, ...rest }, ref) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading]);
    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly }}
        disabled={disabled || isLoading}
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : children}
      </MDButtonRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDLoadingButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
  isLoading: false,
};

// Typechecking props for the MDButton
MDLoadingButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient", "solvership"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "solvershipExtraGreen",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default MDLoadingButton;
