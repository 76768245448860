import { Grid } from "@mui/material";
import { MDBox } from "components";
import PageLayout from "components/Main/PageLayout";

function publicTemplate(Component) {
  return function LayoutComponent(props) {
    return (
      <PageLayout>
        <MDBox width="calc(100% - 2rem)" borderRadius="xl" mx={2} my={2} pt={6} pb={28} />
        <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" mx="auto">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Component {...props} />
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    );
  };
}

export default publicTemplate;
